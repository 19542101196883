import { useState } from 'react';
import './App.css';

const Query = `
  query{
    pairs {
      id
      token0 {
        id
        symbol
        name
        derivedETH
      }
      token1 {
        id
        symbol
        name
        derivedETH
      }
      reserve0
      reserve1
      reserveUSD
      trackedReserveETH
      token0Price
      token1Price
      volumeUSD
      txCount
    }
  }
  `;
const Url = "https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2";
//const Url = "https://gateway.thegraph.com/api/c2e2ab0c908ce67ef1d29daae8a786e6/subgraphs/id/ELUcwgpm14LKPLrBRuVvPvNKHQ9HvwmtKgKSH6123cr7";
async function FetchData(url: string, query : string):Promise<any>{
  
  return fetch(url, {
    method: "POST",
    headers: {"Accept":"application/json, text/plain, /","Content-Type": "multipart/form-data"},
    body: JSON.stringify({
      query
    })
  }).then(response => {
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    return response.json()
  })
  .then(data => { /* <-- data inferred as { data: T }*/
    console.log(data);
    return data.data
  });
}

function App() {
  
  const [pairs, setPairs] = useState<Array<any>>([{
    "id": "loading...",
    "token0": {
        "id": "loading...",
        "symbol": "loading...",
        "name": "loading...",
        "derivedETH": "loading..."
    },
    "token1": {
        "id": "loading...",
        "symbol": "loading...",
        "name": "loading...",
        "derivedETH": "loading..."
    },
    "reserve0": "loading...",
    "reserve1": "loading...",
    "reserveUSD": "loading...",
    "trackedReserveETH": "loading...",
    "token0Price": "loading...",
    "token1Price": "loading...",
    "volumeUSD": "loading...",
    "txCount": "loading..."
}]);

  const onClickHandler = async () => {
    const ObjectData = await FetchData(Url, Query);
    console.log(ObjectData.pairs);
    setPairs(ObjectData.pairs);
  }
  
  return (
    <div className="App App-header">
      
      <button onClick={onClickHandler}>Send Request</button>
      <div className='app-table'>
        <table>
          <thead>
            <tr>
              <th>Pair address</th>
              <th>Pair name</th>
              <th>Volume Coin 0</th>
              <th>Volume Coin 1</th>
              <th>Price Coin 0</th>
              <th>Price Coin 1</th>
            </tr>
          </thead>
          <tbody>
            {pairs.map((pair:any)=>(
              <tr>
              <td>{pair.id}</td>
              <td>{pair.token0.symbol} / {pair.token1.symbol}</td>
              <td>{pair.reserve0}</td>
              <td>{pair.reserve1}</td>
              <td>{pair.token0Price}</td>
              <td>{pair.token1Price}</td>
              </tr>
            ))}
            
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default App;